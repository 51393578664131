import { FC, InputHTMLAttributes } from "react";

interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
}

const BaseInput: FC<BaseInputProps> = ({ className, ...props }) => {

  return (
    <div className={`${className} relative base-input-wrapper`}>
      <input
        className='base-input w-full'
        {...props}
      />
    </div>
  );
};

export default BaseInput;