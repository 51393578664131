import { FC } from "react";
import IconArrow from "./icons/IconArrow";
import uuidv4 from "../helpers";

type BaseAccordionItem = {
  id: string | number
  title: string
  text: string
}

interface BaseAccordionProps {
  items: BaseAccordionItem[]
  className?: string
}


const BaseAccordion: FC<BaseAccordionProps> = ({ items, className }) => {

  const accordion_id = uuidv4()

  const onClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    const element = document.getElementById(id)!
    const element_p = document.getElementById(id + "_p")!

    if (element.style.marginTop === '-20rem') {

      element.style.marginTop = '0'


      element_p.style.opacity = '1'


      event.currentTarget.children[0].classList.add('font-bold')
      event.currentTarget.children[1].classList.remove('rotate-90')
      event.currentTarget.children[1].classList.add('-rotate-90')

    } else {

      element.style.marginTop = '-20rem'


      element_p.style.opacity = '0'


      event.currentTarget.children[0].classList.remove('font-bold')
      event.currentTarget.children[1].classList.add('rotate-90')
      event.currentTarget.children[1].classList.remove('-rotate-90')
    }

  }

  return (
    <div className={`${className} max-w-4xl w-full flex flex-col gap-[1.5rem]`}>
      {items.map(({ id, title, text }) => {
        const item_id = `accordion_${accordion_id}_item_${id}`
        return (
          <div id={`accordion_${accordion_id}`} key={id} className="pb-[2.5rem] border-b border-white border-opacity-50 relative overflow-hidden">
            <button className="w-full flex justify-between cursor-pointer gap-s" onClick={(e) => onClick(e, item_id)}>
              <p className="pointer-events-none text-left">{title}</p>
              <IconArrow className="shrink-0 mr-[0.5rem] rotate-90 rotate transition-all" />
            </button>
            <div className="w-full overflow-hidden transition-all" id={item_id} style={{ marginTop: '-20rem' }}>
              <p className="relative pt-[2.5rem] pb-[1rem] accordion-transition" id={item_id + "_p"} style={{ opacity: '0' }}>{text}</p>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default BaseAccordion;
