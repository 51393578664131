const benefits: JSX.Element[] = [
  <>Perfectly working software</>,
  <>In-house development</>,
  <>Over 800 satisfied clients</>,
  <>Free software updates</>,
  <>24/7 support</>,
  <>Profit without any risk</>,
]

const Benefits = () => {
  return (
    <section id='benefits' className='relative flex flex-col justify-center items-center mb-3xl max-w-[1440px] mx-auto px-xl lg:px-l sm:px-2xs'>
      <div className='absolute layer_gradient h-[25%] w-[40%] !top-[40%]'></div>
      <div className='flex justify-between w-full pt-2xl m:flex-col m:gap-2xs'>
        <h2 className='text-h-2 text-primary uppercase ml-2xl tablet:text-h-3 m:ml-l sm:ml-0'>Crypto<br />keys <img className="inline align-sub" src="/images/key.png" alt="" /></h2>
        <p className='text-[32px] leading-9 text-white max-w-[30rem] tablet:text-[30px] tablet:max-w-[28rem] m:ml-l sm:ml-0'>Have you ever dreamed of a key that unlocks the cryptocurrency world? We have it!</p>
      </div>
      <p className='mt-xl text-body text-center max-w-5xl'>Our software is a way to a new level of financial management.<br /> We don't just restore access to your wallet, we create endless possibilities.</p>
      <div className='mt-[10.94rem] flex flex-row flex-wrap gap-s justify-center'>
        {benefits.map((benefit, idx) => (
          <div key={idx} className='benefit-card flex items-center justify-center w-[24.76rem] h-[12.5rem] py-xl px-xs'>
            <p className='text-with-bg text-[32px] leading-9 font-medium text-center'>{benefit}</p>
          </div>
        )
        )
        }
      </div>
    </section>
  );
};

export default Benefits;