import IconBurgerMenu from "../icons/IconBurgerMenu";
import MobileMenu from "../MobileMenu";
import logo_img from "../../images/logo.svg"
import BaseButton from '../BaseButton';
import BaseLink from "../BaseLink";

export const headerLinks = [
  { text: 'How it works', id: 'benefits' },
  { text: 'Price', id: 'price' },
  { text: 'FAQ', id: 'faq' }
];

export const generateLinks = (arr: { text: string, id: string }[], onClick?: () => void) => {
  return arr.map(item => {
    return (
      <a
        className={item.id === 'price' ? 'm:hidden' : ''}
        href={`#${item.id}`}
        key={item.id}
        onClick={onClick}
      >
        {item.text}
      </a>
    );
  });
};

const menuLinks = [
  { text: 'How it works', id: 'benefits' },
  { text: 'Price', id: 'price' },
  { text: 'FAQ', id: 'faq' },
];

export const Header = () => {
  const handleMenuToggle = () => {
    const menu = document.querySelector("#mobile-menu")!;
    menu.classList.toggle('hidden');
  };

  const onRequestClick = () => {
    const element = document.getElementById('questions')!
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <header className="w-full z-20 absolute left-[0] p-[inherit]">
      <div className="flex items-center justify-between h-[9.375rem] max-w-[1440px] mx-auto px-xl lg:px-l sm:px-2xs">
        <a
          href='/'
        >
          <img src={logo_img} alt='logo' className="max-w-[130px] max-h-[130px]" />
        </a>
        <div className="text-white tablet:hidden space-x-s font-medium">
          {generateLinks(headerLinks)}
        </div>
        {/* <BaseButton
          button_type='primary'
          className='font-medium sm:hidden'
          onClick={onRequestClick}
        >Leave a request</BaseButton> */}
        <BaseLink
          type='primary'
          className='font-medium sm:hidden'
          href='https://t.me/cryptokeys_tm'
        >Leave a request</BaseLink>
        <button
          className="hidden tablet:block"
          onClick={handleMenuToggle}
        >
          <IconBurgerMenu className='w-l h-l' />
        </button>
      </div>
      <MobileMenu
        nav={generateLinks(menuLinks, handleMenuToggle)}
        onClose={handleMenuToggle}
      />
    </header>
  );
};