import { useState } from "react";
import BaseButton from "../components/BaseButton";
import BaseInput from "../components/BaseInput";
import BaseModal from "../components/BaseModal";
import logo_img from "../images/logo.svg"
import BaseLink from "../components/BaseLink";


interface FormElements extends HTMLFormControlsCollection {
  _name: HTMLInputElement
  tg_name: HTMLInputElement
}

interface RequestFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

const Questions = () => {

  const [isModalOpened, setModalOpened] = useState<boolean>(false)


  const onSubmitFrom = async (event: React.FormEvent<RequestFormElement>) => {
    event.preventDefault()
    const { _name, tg_name } = event.currentTarget
    console.log('name === ' + _name.value)
    console.log('tg_name === ' + tg_name.value)

    // request emulate
    setTimeout(() => {


      setModalOpened(true);
    }, 1000);
  }


  return (
    <>
      <section className="max-w-[1440px] mx-auto px-xl lg:px-l sm:px-2xs">
        <div id='questions' className='relative flex flex-col questions-layer mt-2xl mb-s px-2xl sm:px-s xs:px-[10px]'>
          <div className='absolute questions-layer_gradient -z-1'></div>
          <h2 className='text-h-2 text-with-shadow mt-xl xs:text-h-3'>Any other questions?</h2>
          <p className="mt-l">Leave your contact details and our managers will get back to you as soon as possible</p>
          <form className="mt-s flex flex-col items-center gap-l" onSubmit={onSubmitFrom}>
            <div className="flex justify-between gap-xs w-full m:flex-col">
              <BaseInput className="w-full" placeholder="Name" name='_name' id='_name' />
              <BaseInput className="w-full" placeholder="Telegram nickname" name='tg_name' id='tg_name' />
            </div>
            <BaseLink className='relative' type="primary" href="https://t.me/cryptokeys_tm">Leave a request</BaseLink>
          </form>
        </div>
      </section>
      <BaseModal
        id='feedback_modal'
        isOpened={isModalOpened}
        onClose={() => setModalOpened(false)}
      >
        <div className="flex flex-col justify-center items-center gap-s pt-s">
          <img className="max-w-[150px]" src={logo_img} alt='logo' />
          <h3 className="text-primary text-h-3 text-center">Thank you<br /> for your application!</h3>
          <p className="text-white">Our admin will contact you</p>
        </div>
      </BaseModal>
    </>

  );
};

export default Questions;