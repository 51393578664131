import React from 'react';
import BaseLink from '../components/BaseLink';

const Hero = () => {
  return (
    <section id='hero' className='relative pt-[12.5rem] pb-xl flex flex-col items-center'>
      {/* <div className='absolute hero-layer h-[50%] w-[50%] top-[-6rem]'></div> */}
      <div className='max-w-[1440px] mx-auto px-xl lg:px-l sm:px-2xs flex flex-col items-center z-10'>
        <h1 className='text-h-1 tablet:text-h-2 xs:text-h-3 text-center max-w-7xl text-with-shadow'>Access to your cryptocurrency wealth without restrictions!</h1>
        <BaseLink type='secondary' className='mt-xl font-medium' href='https://t.me/cryptokeys_tm'>Join Telegram</BaseLink>
      </div>
    </section>
  );
};

export default Hero;